/* eslint-disable no-underscore-dangle */
import {
    YMaps, Map, SearchControl, Placemark,
} from '@pbe/react-yandex-maps';
import {
    Button, Form, Input, Modal, Space, Spin,
} from 'antd';
import { produce } from 'immer';
import { useEffect, useState } from 'react';
import YandexGeocoder from '../../services/yandex-geocoder';
import { IFormItemProps } from '../../factories/form';
import { useGeolocationAddress } from '../../hooks/useGeolocationAddress';
import { useYandexMapToken } from '../../hooks/useYandexMapToken';

function GeolocationAddress({ formRef, formDataLoaded }: IFormItemProps) {
    const [address, setAddress] = useState<string|undefined>();
    const [coords, setCoords] = useState<[number, number]|undefined>();
    const [visible, setVisible] = useState(false);
    const { center, loading } = useGeolocationAddress();
    const yandexMap = useYandexMapToken();

    const processGeolocationAddress = async (geolocation: [number, number]) => {
        if (typeof yandexMap.token === 'string') {
            try {
                const result = await YandexGeocoder.getAddress(
                    produce(geolocation, (draft: [number, number]) => { draft.reverse(); }),
                    yandexMap.token,
                );
                const { text } = result.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData;
                if (typeof text === 'string') {
                    setAddress(text);
                    formRef.setFieldsValue({
                        address: text,
                    });
                }
            } catch (err) {
                setAddress('Ошибка поиска');
            }
        }
    };

    useEffect(() => {
        if (Array.isArray(coords)) {
            processGeolocationAddress(coords);
            formRef.setFieldsValue({
                latitude: typeof coords?.[0] === 'number' ? coords[0].toString() : undefined,
                longitude: typeof coords?.[1] === 'number' ? coords[1].toString() : undefined,
            });
        }
    }, [coords]);

    useEffect(() => {
        if (formDataLoaded) {
            const addressText = formRef.getFieldValue('address');
            const latitude = formRef.getFieldValue('latitude');
            const longitude = formRef.getFieldValue('longitude');

            if (typeof addressText === 'string') {
                setAddress(addressText);
            }

            if (latitude && longitude) {
                setCoords([latitude, longitude]);
            }
        }
    }, [formDataLoaded]);

    const onBtnClick = async () => {
        await yandexMap.getToken();
        setVisible(true);
    };

    const onClose = () => setVisible(false);

    const onMapClick = (e: any) => {
        setAddress(undefined);
        setCoords(e.get('coords'));
    };

    const onInstanceRef = (ref: any) => {
        if (ref) {
            ref?.events?.add('click', onMapClick);
        }
    };

    const onSearchInstanceRef = (ref: any) => {
        if (ref) {
            ref?.events?.add('resultselect', () => {
                const arr = ref.getResultsArray();
                if (Array.isArray(arr) && arr.length > 0) {
                    const [data] = ref.getResultsArray();
                    if (Array.isArray(data?.geometry?._coordinates)) {
                        setCoords(data.geometry._coordinates);
                    }
                }
            });
        }
    };

    return (
        <>
            <Space direction="vertical" style={{ width: '100%' }}>
                {address && <Input.TextArea readOnly value={address} />}
                <Button onClick={onBtnClick} type="primary" loading={yandexMap.loading}>Указать адрес</Button>
            </Space>
            <Form.Item name="address" hidden />
            <Form.Item name="latitude" hidden />
            <Form.Item name="longitude" hidden />
            <Modal visible={visible} onCancel={onClose} width="80%" onOk={onClose}>
                <Spin spinning={loading}>
                    <YMaps query={{ apikey: yandexMap.token }}>
                        <Map
                            key={center[0]}
                            instanceRef={onInstanceRef}
                            defaultState={{ center, zoom: 9 }}
                            width="100%"
                            height="65vh"
                        >
                            <SearchControl instanceRef={onSearchInstanceRef} options={{ float: 'right', noPlacemark: true }} />
                            {Array.isArray(coords) && (
                                <Placemark
                                    geometry={coords}
                                    options={{ preset: 'islands#violetDotIconWithCaption' }}
                                    properties={{ iconCaption: address || 'Поиск...' }}
                                />
                            )}
                        </Map>
                    </YMaps>
                </Spin>
            </Modal>
        </>
    );
}

export default GeolocationAddress;
