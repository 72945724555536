import { useState } from 'react';
import { useApiFetch } from './api/useApiFetch';

export const useYandexMapToken = () => {
    const api = useApiFetch();
    const [token, setToken] = useState<string>();
    const [loading, setLoading] = useState(false);

    const getToken = async () => {
        setLoading(true);

        const response = await api.fetchData<{ token: string }>('/yandex/map/token', 'get', true);

        if (response.success && response.data && typeof response.data.token === 'string') {
            setToken(response.data.token);
        }

        setLoading(false);

        return response.data?.token;
    };

    return {
        getToken,
        token,
        loading,
    };
};
